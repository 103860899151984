const ART = [
  // {
  //   img: 'cookie.JPG',
  //   title: 'My cat, Cookie',
  // },
  
  {
    img: 'alissa3.png',
    title: 'alissa (progress)',
  },
  {
    img: 'still4.png',
    title: 'alissa (progress)',
  },
  {
    img: 'parents.jpg',
    title: 'parents',
  },
  {
    img: 'emille.jpg',
    title: 'parents',
  },
  {
    img: 'veg.png',
    title: 'veg',
  },
  {
    img: 'alissa.png',
    title: 'veg',
  },
  {
    img: 'Piece1.jpg',
    title: 'P',
  },
  {
    img: 'Piece2.jpg',
    title: 'P',
  },
  {
    img: 'Piece3.jpg',
    title: 'P',
  },
  {
    img: 'Piece4.jpg',
    title: 'P',
  },
  {
    img: 'Piece5.jpg',
    title: 'P',
  },
  {
    img: 'Piece6.jpg',
    title: 'P',
  },
  {
    img: 'Piece8.jpg',
    title: 'P',
  },
  {
    img: 'Piece9.jpg',
    title: 'P',
  },
  {
    img: 'Piece10.jpg',
    title: 'P',
  },
  {
    img: 'Piece11.jpg',
    title: 'P',
  },

  {
    img: 'ally.png',
    title: 'P',
  },
  {
    img: 'iago.png',
    title: 'P',
  },
  {
    img: 'nft.png',
    title: 'P',
  },
  {
    img: 'madison.png',
    title: 'P',
  },
  {
    img: 'amma.png',
    title: 'P',
  },
  {
    img: 'brent.png',
    title: 'P',
  },
  {
    img: 'song.png',
    title: 'P',
  },
  {
    img: 'em.png',
    title: 'P',
  },
  {
    img: 'self.png',
    title: 'P',
  },
  {
    img: 'mango.png',
    title: 'P',
  },
  {
    img: 'coke.png',
    title: 'P',
  },
  {
    img: 'fka.png',
    title: 'P',
  },
  {
    img: 'pear.png',
    title: 'P',
  },
];
export default ART;